.listItem {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    background-color: white;
    margin: .2rem 0;
    font-size: 1.2rem;
    color: #333;
}

.username {
    margin: .3rem;
    font-size: 2rem;
    font-weight: 300;
}

.email {
    margin: .3rem;
    margin-left: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--primary);
}

.loggedin {
    margin: .3rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.icon {
    font-size: 2.8rem;
    margin: 0;
    color: #999;
    margin-left: -1rem;
}

.deviceIcon {
    font-size: 2rem;
    margin: 0;
    color: #999;
}

.userInfoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
   
}

.flag {
    margin: auto 0;
    padding: 0;
    height: 2rem;
}

.ip {
    font-size: 1.4rem;
}

.infoContainer {
    display: flex;
    color: grey;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    width: 100%;
}

.infoContainer p {
    margin: 1rem;
}