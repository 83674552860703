.mapContiner {
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 50rem;
    width: 50%;
    border-left: 0px solid #333;
}

.resetBtn {
    visibility: visible;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: 1px solid var(--primary-500);
    background-color: var(--primary);
    color: white;
    padding: 1rem 2rem;
    border-radius: .5rem;
    transition: .3s;
}

.resetBtn:hover {
    background-color: var(--primary-500);
}

.hidden {
    visibility: hidden;
}