#streamSelector {
    margin: 0;
    padding: 0;
    background-color: var(--primary);
    height: 6rem;
    width: 100%;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    margin-left: 2.5rem;
    font-size: 2.5rem;
    font-weight: 300;
}

.actions {
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 30rem;
}

.text {
    font-size: 1.6rem;
    font-weight: 400;
    width: 25rem;
}

.selector {
    margin-bottom: -1rem;
    background-color: black;
    color: white;
    border-radius: .5rem;
    border: 0;
}