#streamPreviewWrapper {
    position: relative;
    margin: 0;
    padding: 0;
    padding-top: 6rem;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.previewHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.mainTitle {
    margin-left: 1rem;
    color: white;
    font-weight: 900;
    font-size: 2rem;
    text-align: left;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
    min-width: 50rem;
    max-width: 106.6rem;
    max-height: 63rem;
}

.player {
    width: 100%;
    max-height: 50rem;
    max-width: 80rem;
}