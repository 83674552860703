.outerWrapper {
    width: 100vw;
    max-height: 50rem;
    max-width: 80rem;
    background-color: black;
    color: white;
}

.placeholder {
    padding: 56.25% 0 0 0;
    position: relative;
}

.innerFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    font-size: 2.4rem;

}

.text {
    font-size: 1.6rem;
    text-align: center;
}