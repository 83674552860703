#event-list{
    
}

.no-event-list {
    margin: 0;
    padding:0;
    margin-top: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-event-textbox{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
}

.no-events-title{
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
    color: grey;
}
.no-events-text {
    margin: .3rem;
    font-size: 1.4rem;
    color: grey;
}