#WOL {
    margin: 0;
    padding: 0;
    width: 50%;
    flex-grow: 1;
    background-color: #b0b0b0;
}

.noUsers {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    color: var(--secondary);
    font-size: 2rem;
}

.noUsers h1 {
    font-size: 1.6rem;
    margin-left: 1rem;
}


.title {
    font-size: 2.6rem;
    margin-left: 2rem;
    margin-bottom: .2rem;
    font-weight: 400;
    color: white;
}