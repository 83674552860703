.previewTitle{

    position: absolute;
    padding: .1rem 2rem;
    bottom: 5rem;
    left: 2rem;
    background-color: black;
    color: white;
    border-radius: .5rem;
    border: 1px solid var(--primary);
}

.titleLabel {
    font-size: 1.2rem;
    font-weight: 600;
    margin: .3rem 0;
    padding: 0;
}

.streamLabel {
    font-weight: 300;
    font-size: 1.6rem;
    margin-left: .5rem;
}