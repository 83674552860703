
:root {
  --primary-100: #CD3525;
  --primary: #C21807;
  --primary-500: #9A1609;

  --secondary-100: #4E4E4E;
  --secondary: #333;
  --secondary-500: #292929;

  --input-color: #797979;
  --input-border: #8c8c8c;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: var(--primary-500);

  --group-color: #333;
  --group-border: var(--input-border);
  --group-background: #ededed;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  color: var(--primary);
  background-color: whitesmoke;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 9rem;
  margin-bottom: 5.6rem;
  width: 100%;
  max-width: 1750px;
  min-height: 500px;
}

a {
  color: var(--primary);
  text-decoration: none;
}

.clear-header {
  padding-top: 90px !important;
}

a:hover {
  color: var(--primary-500);
}

#root {
  width: 100%;
  display: flex;
}

.text-error {
  font-size: 1.2rem;
  color: darkred;
}