.pageWrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-grow: 2;
    width: 100%;
}

.attendeesContainer {
    position: relative;
    width: 100%;
    

}

.whosonlineWrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    
}