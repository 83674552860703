main {
  width: 100%;
  display: flex;
  align-content: stretch;
  padding: 0 ;
}

.page__wrapper {
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 2;
}

.page-content {
  position: fixed;
  width: calc(100vw - 149px);
  padding: 0px;
  
  flex-grow: 2;
}

.spinner-margin-top {
  margin-top:100px;
}